import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import ReactGA from "react-ga4";
import React, {useEffect} from "react";


const Home = () => {
    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_CODE);
        ReactGA.send({hitType: "pageview", page: "/", title: "Home"});
    }, []);

    return (
        <>
            <Hero />
            <Intro />
            <Services />
            <Portfolio />
            <Clients />
            <Cta/>
            <Footer />
        </>
    )
}

export default Home;

