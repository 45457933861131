import Footer from '../components/Footer';
import ReactGA from "react-ga4";
import React, {useEffect} from "react";
import {HashLink} from "react-router-hash-link";
import NavBar from "../components/Navbar/NavBar";

import sm1 from '../images/supermanager/sm1.png';
import sm2 from '../images/supermanager/sm2.png';
import sm3 from '../images/supermanager/sm3.png';
import sm4 from '../images/supermanager/sm4.png';
import sm5 from '../images/supermanager/sm5.png';
//import sm6 from '../images/propaganda.svg';
import sm6 from '../images/supermanager/sm6.jpg';
import realTimeVideo from '../images/real-time.mp4';
import sponsors_pitch from '../images/sponsors-pitch.png';
import {Link} from "react-router-dom";


const SupermanagerProductLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/supermanager#about">
                About
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/supermanager#features">
                Services
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/supermanager#details">
                Portfolio
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/supermanager#contact">
                Contact Us
            </HashLink>
            <HashLink className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/get-demo#demo">
                Request a demo
            </HashLink>
        </>
    )
}


const Supermanager = () => {
    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_CODE);
        ReactGA.send({hitType: "pageview", page: "/supermanager", title: "Supermanager"});
    }, []);

    function triggerClickToAsobal(e) {
        ReactGA.event({
            category: "cta",
            action: "cta",
            label: "asobal"
        })
    }

    function triggerClickToSupermanager(e) {
        ReactGA.event({
            category: "cta",
            action: "cta",
            label: "supermanager"
        })
    }

    return (
        <>
            <div className="hero" id='hero'>
                <div>
                    <NavBar />
                </div>

                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-15 p-2 md:p-12 h-5/6" data-aos="zoom-in">

                    <div id='hero' className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                A Fantasy league to improve the engagement with your fans!
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="m-auto max-w-6xl p-1 md:p-5 h-5/6 mt-20 bg-gray-100" id='about' >
                <div className="text-center lg:text-left lg:my-0 lg:justify-end w-full px-8" data-aos="zoom-in" data-aos-delay="500">
                    <h2 className="text-3xl text-blue-900 font-bold">See it online:</h2>
                </div>
                <div className="flex flex-wrap justify-center sm:justify-between px-8">
                    <div className="text-center lg:text-left lg:my-0 lg:justify-end w-full sm:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <p className='my-3 text-xl text-gray-600 font-semibold'>
                                Now playing:
                            </p>
                            <p className='text-xl text-gray-600 font-semibold'>
                                <a className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                                   target="_blank"
                                   href="https://fantasy.asobal.es" onClick={triggerClickToAsobal}>
                                    ASOBAL Fantasy 24/25
                                </a>
                            </p>
                    </div>
                    <div className="text-center lg:text-left lg:my-0 lg:justify-end w-full sm:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <p className='my-3 text-xl text-gray-600 font-semibold'>
                            Soon...
                        </p>
                        <p className='text-xl text-gray-600 font-semibold'>
                            <a className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                               target="_blank"
                               href="https://supermanager.dootic.com" onClick={triggerClickToSupermanager}>
                                EHF Women Euro 2024
                            </a>
                            <br/>
                            <small style={{fontSize: "0.6rem"}}>
                                <a href="https://ehfeuro.eurohandball.com/women/2024/">🔗 Official site of EHF Women Euro 2024</a>
                            </small>
                        </p>
                    </div>
                </div>
            </div>

            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6 mt-20 bg-gray-100" id='about' >
                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                        <img className="duration-1000" width="350px" src={sm1} style={{border: '1px solid grey'}} alt="Squad view"/>
                    </div>
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h2 className="text-3xl  text-blue-900 font-bold mb-5">Features</h2>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>
                            <ul style={{listStyle: "initial"}} className="my-3">
                                <li>Web-based. A specific web app can be created and it can be easily integrated in your app. Think of this screen captures as a canvas, where the colors, logo, font, etc. can ben adapted to your corporate styling.</li>
                                <li>Users will access the game through the client's app or website by linking to the web app.</li>
                                <li>They can create a team of 7 outfield players + 4 substitutes.</li>
                                <li>
                                    A captain can be selected, who will score with a configurable multiplier.
                                </li>
                                <li>
                                    Each round, players can make changes using the allowed number of transfers (configurable) and unlimited changes with the bench.
                                </li>
                            </ul>
                            </p>
                    </div>
                </div>
            </div>

            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <h4 className="text-3xl  text-blue-900 font-bold mb-5">Ranking</h4>
                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>Players compete in a general league, where details of the last matchday and the overall standings are displayed.</p>
                        </div>
                        <h4 className="text-3xl  text-blue-900 font-bold mt-5 mb-5">Mini-leagues</h4>
                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>Players can also create private leagues, mini-leagues, and invite their friends and acquaintances via Telegram, WhatsApp, QR code, or direct link.</p>

                            <p className='my-3 text-xl text-gray-600 font-semibold'>
                                The private leagues have 3 formats:
                                <ul className="pl-6">
                                    <li>Classic.</li>
                                    <li>Broker (by team value + budget).</li>
                                    <li>Goals + saves.</li>
                                </ul>
                            </p>
                            <p>
                                Minileagues can be taylored for Teams participating in your tournament by adding
                                custom access link, badge, custom background and custom text, so users
                                can join (by code or automatically upon registering) their favourite team minileague, and
                                teams can manage its own league and generate engagement by giving prizes (2 tickets for the
                                next match, meet-and-greet, etc.)
                            </p>
                        </div>

                        <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                            Contact us
                            <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                    </div>
                    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                        <img className="duration-1000" width="350px" src={sm3} style={{border: '1px solid grey'}} alt="Ranking"/>
                    </div>
                </div>
            </div>

            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6 bg-gray-100" id='about' >
                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                        <img className="duration-1000" width="350px" src={sm4} style={{border: '1px solid grey'}} alt="Mini leagues"/>
                    </div>
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h4 className="text-3xl  text-blue-900 font-bold mt-5 mb-5">Tournaments</h4>
                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>The game offers the possibility of having different tournaments and allowing users to access them, changing competitions without changing applications.</p>
                        </div>

                        <h4 className="text-3xl  text-blue-900 font-bold mt-5 mb-5">News</h4>
                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>The game includes a news section where the client can provide news about the game, or where news can be generated after each round based on the best players and users using artificial intelligence.</p>
                        </div>


                        <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                            Contact us
                            <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6 mt-20" id='about' >
                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                <div id='details' className="mb-5">&nbsp;</div>
                <h3 className="text-3xl  text-blue-900 font-bold mb-5">Product details</h3>
                <p className='my-3 text-xl text-gray-600 font-semibold'>
                <ul style={{listStyle: 'none'}}>
                    <li className="text-2xl mt-5">Fully automated</li>
                    <li>
                        <ul style={{listStyle: 'revert', margin: 'unset'}}>
                            <li>Player prices are updated automatically.</li>
                            <li>Player points are automatically updated by reading match results. If an API is available to facilitate reading, response times are minimized.</li>
                            <li>The system automatically opens and closes the transfer windows. The opening and closing dates of the transfer windows are configurable.</li>
                        </ul>
                    </li>
                    <li className="text-2xl mt-5">Fully configurable</li>
                    <li>
                        <ul style={{listStyle: 'revert', margin: 'unset'}}>
                            <li>Player prices are updated automatically.</li>
                            <li>Player points are automatically updated by reading match results. If an API is available to facilitate reading, response times are minimized.</li>
                            <li>The system automatically opens and closes the transfer windows. The opening and closing dates of the transfer windows are configurable.</li>
                        </ul>
                    </li>
                    <li className="text-2xl mt-5">Multi-language</li>
                    <li>
                        <ul style={{listStyle: 'revert', margin: 'unset'}}>
                            <li>The system is available in English, Spanish, and Catalan, but any language can be added upon request (to be provided by customer).</li>
                            <li>Users can change the language at any time.</li>
                        </ul>
                    </li>
                    <li className="text-2xl mt-5">Real time update</li>
                    <li>
                        <ul style={{listStyle: 'revert', margin: 'unset'}}>
                            <li>When match is ongoing, users can see their players score (if real time stats are available). See video.</li>
                        </ul>
                    </li>

                    <li className="text-2xl mt-5">Multi-team per player</li>
                    <li>
                        <ul style={{listStyle: 'revert', margin: 'unset'}}>
                            <li>Any user can have multiple teams.</li>
                            <li>Can be configured per tournament.</li>
                        </ul>
                    </li>
                    <li className="text-2xl mt-5">Compare players</li>
                    <li>
                        <ul style={{listStyle: 'revert', margin: 'unset'}}>
                            <li>Users can compare players and theirs stats inside the game.</li>
                            <li>
                                Optionally, a link to the stats in the tournament site can be configured, so your
                                users visit your site for more info.
                            </li>
                        </ul>
                    </li>
                    <li className="text-2xl mt-5">Dedicated support</li>
                    <li>
                        <ul style={{listStyle: 'revert', margin: 'unset'}}>
                            <li>Dedicated staff is available on match days to handle any potential incidents.</li>
                        </ul>
                    </li>

                    <li className="text-2xl mt-5">Custom ads</li>
                    <li>
                        <ul style={{listStyle: 'revert', margin: 'unset'}}>
                            <li>Sponsor images and links can be set to monetize the game.</li>
                        </ul>
                    </li>

                    <li className="text-2xl mt-5">Undo changes</li>
                    <li>
                        <ul style={{listStyle: 'revert', margin: 'unset'}}>
                            <li>Users can revert changes made since the last transfer window opening date.</li>
                        </ul>
                    </li>

                    <li className="text-2xl mt-5">Injured players</li>
                    <li>
                        <ul style={{listStyle: 'revert', margin: 'unset'}}>
                            <li>Injured players are highlighted to inform users (requires notification of injured players by the client).</li>
                        </ul>
                    </li>
                </ul>
                </p>
            </div>
                    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-right">
                        <img className="duration-1000" width="350px" src={sm5} style={{border: '1px solid grey'}}/>
                        <br/>
                        <video width="350px" style={{border: '1px solid grey'}} controls>
                            <source src={realTimeVideo} type="video/mp4"/>
                        </video>
                        <br/>
                        <img className="duration-1000" width="350px" src={sm6} style={{border: '1px solid grey'}}/>
                    </div>
                </div>
            </div>

            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6 bg-gray-100" id='about' >
                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                        <img className="duration-1000" width="350px" src={sponsors_pitch} style={{border: '1px solid grey'}} alt="Sponsors"/>
                    </div>
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h4 className="text-3xl  text-blue-900 font-bold mt-5 mb-5">Sponsors</h4>
                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>See how could your sponsors look in the game</p>
                            <ul>
                                <li>Minilogos in the header, at left, right or both</li>
                                <li>Either with or without white background in the pitch</li>
                                <li>Banner between bench and player list</li>
                                <li>Ranking</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className=" bg-gray-100">
                <div style={{display: 'flex', justifyContent: 'between'}}></div>
                <div className="hidden">Standard</div>
                <div className="hidden">Plus</div>
                <div className="hidden">Premium</div>

                <div>
                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                        Get more information
                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </Link>
                </div>
            </div>

            <Footer></Footer>
        </>
    )
}

export default Supermanager;

